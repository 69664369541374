import { FC, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "../../authConfig";

const MsalLogin: FC = () => {
  const {instance} = useMsal();

  const doLogin = async (): Promise<void> => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (e: any) {
      console.error(e.message);
    }
  }

  useEffect((): void => {
    doLogin();
  }, [doLogin]);
  

  return (
    <>
      Redirect...
    </>
  );
};

export default MsalLogin;
