import { IOrder, PlaceEnum, DeliveryMethodEnum } from "../../types/types";
import styles from "../column/styles.module.scss";
import { format } from "date-fns";
import React, { FC } from "react";


interface Props {
  order: IOrder
  place: PlaceEnum
}
export const OrderDetails: FC<Props> = ({order, place}) => {

  if (place === PlaceEnum.KITCHEN) {
    return (
      <>
        <div className={styles.selectedPlace}>
          <h4>Tilaus</h4>
          <li className={order.deliveryMethod === DeliveryMethodEnum.TAKE_AWAY ? styles.away : styles.inside}>
            {order.deliveryMethod === DeliveryMethodEnum.TAKE_AWAY ? 'Otan mukaan' : 'Paikan päällä'}
          </li>
        </div>
        <div className={styles.timeAndNumber}>
          <li className={styles.time}>{format(order.orderDate, 'dd.mm.yyyy HH:MM')}</li>
          <li>Tilaus no: {order.serviceID}</li>
        </div>
      </>
    );
  } else {
    return (
      <div className={styles.number}>
        <li>{order.serviceID}</li>
      </div>
    );
  }
};