import { IOrder } from "../types/types";

export const orderFiltering = (orders: IOrder[], selectedFilter: string[]): IOrder[] => {
  if (selectedFilter.length === 0) {
    return orders;
  }

  return orders.map(order => ({
    ...order,
    orderedProducts: order.orderedProducts.filter(product => selectedFilter.includes(product.conseptName))
  })).filter(order => order.orderedProducts.length > 0);
};