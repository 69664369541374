interface ApiConfig {
  apiUrl: string;
  apiKey: string;
  paycoUrl: string;
}

const apiUrl: string = process.env.REACT_APP_BASKET_URI || '';
const apiKey: string = process.env.REACT_APP_BASKET_KEY || '';
const paycoUrl: string = process.env.REACT_APP_PAYCOENV || '';

export const getConfig = (): ApiConfig => {
  return {apiUrl: apiUrl, apiKey: apiKey, paycoUrl: paycoUrl };
};