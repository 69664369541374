import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import { Title } from "../../title/Title";
import { RadioButton } from "../../radioButton/RadioButton";
import { useTranslation } from "react-i18next";


interface Props {
  selectedLanguage: (language: string) => void
}
export const LanguageContent: FC<Props> = ({selectedLanguage}) => {
  const {i18n, t} = useTranslation();
  const [lngValue, setLngValue] = useState<string>(i18n.language);
  
  const handleChangeLanguage = (value: string) => {
    setLngValue(value);
    selectedLanguage(value);
  }
  
  return (
    <div className={styles.languageContent}>
      <Title title={t('change_language')} />
      <div className={styles.content}>
        {
          i18n.languages.map(language => (
            <RadioButton 
              key={language}
              label={language === 'fi' ? 'FI - Suomi' : language === 'en' ? 'EN - Englanti' : 'SV - Ruotsi'} 
              value={language} 
              selected={lngValue === language}
              onChange={handleChangeLanguage} />
          ))
        }
      </div>
    </div>
  );
};
