import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { getConfig } from "./apiConfig";
import { msalInstance } from "../index";
import { tokenRequest, tokenRefreshRequest } from "../authConfig";
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { wait } from "@testing-library/user-event/dist/utils";


const apiUrl: string = getConfig().apiUrl;
const apiKey = getConfig().apiKey;

export const callApi: AxiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {'Content-type': 'application/json'}
})

export const callApiAnonymous: AxiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: false,
  headers: {'Content-type': 'application/json'}
})

callApi.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const accounts: AccountInfo[] = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      let auth: AuthenticationResult = await msalInstance.acquireTokenSilent(tokenRequest).catch(function (error)
      {
          console.log(error);
          return msalInstance.acquireTokenPopup(tokenRequest);
      });
      if (auth) config.headers.Authorization = `Bearer ${auth.accessToken}`
      else config.headers.ApiKey = apiKey;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error)
  }
)

callApi.interceptors.response.use(
  (config: AxiosResponse) => {

    return config;
  },
  (error) => {
    return Promise.reject(error)
  }
)