import styles from './styles.module.scss';
import { useEffect, useState } from "react";
import {format} from 'date-fns';

export const Footer = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval: NodeJS.Timer = setInterval((): void => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formattedDateTime: string = format(currentTime, 'dd.MM.yyyy HH:mm:ss');
  
  return (
    <div className={styles.footer}>
      <div className={styles.time}>
        <span>{formattedDateTime}</span>
      </div>
      <div className={styles.title}>
        <span>PosManager</span>
      </div>
      <div className={styles.logo}>
        <span>CGI</span>
      </div>
    </div>
  );
};
