import React, { FC } from 'react';
import styles from './styles.module.scss';
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { toggleFilter } from "../../store/displaySlice";


interface Props {
  label: string
}
export const CheckBox: FC<Props> = ({label}) => {
  const state = useSelector((state: RootState) => state.display);
  const dispatch = useAppDispatch();

  const isChecked = state.selectedFilter.includes(label);

  const handleCheckboxChange = () => {
    dispatch(toggleFilter(label));
  };
  
  return (
    <div className={styles.checkBox}>
      <label className={styles.checkBoxContainer}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className={styles.checkmark}/>
      </label>
      <div className={styles.label}>
        <span>{label}</span>
      </div>
    </div>
  );
};
