import styles from './styles.module.scss';
import { Column } from "../../components/column/Column";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOrder, PlaceEnum } from "../../types/types";
import { selectOrderStepBack, selectOrderStepNext } from "../../api";
import { OrderItem } from "../../components/kitchenColumn/OrderItem";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { Footer } from "../../components/footer/Footer";
import { SideBar } from "../../components/sideBar/SideBar";
import { SideBarWindow } from "../../components/sideBarWindow/SideBarWindow";


interface Props {
  place: PlaceEnum
}

export const Home: FC<Props> = React.memo(({place}) => {
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.display);
  const {t} = useTranslation();
  const [leftSideActiveTab, setLeftSideActiveTab] = useState(0)
  const [rightSideActiveTab, setRightSideActiveTab] = useState(0)

  const columnTitles: { id: number; title: string }[] = [
    {id: 0, title: t(place === PlaceEnum.KITCHEN ? 'waiting' : 'hall_waiting')},
    {id: 1, title: t(place === PlaceEnum.KITCHEN ? 'in-process' : 'hall_in-process')},
    {id: 2, title: t(place === PlaceEnum.KITCHEN ? 'ready' : 'hall_ready')},
  ];

  const leftSideTabs: { id: number, title: string, content: IOrder[] }[] = [
    {id: 0, title: `${t('waiting')} (${state.waitingOrders.length})`, content: state.waitingOrders}
  ]

  const rightSideTabs: { id: number, title: string, content: IOrder[], productState: string }[] = [
    {
      id: 0,
      title: `${t('in-process')} (${state.preparingOrders.length})`,
      content: state.preparingOrders,
      productState: 'isPrepared'
    },
    {id: 1, title: `${t('ready')} (${state.readyOrders.length})`, content: state.readyOrders, productState: 'isCollected'},
    {id: 2, title: `${t('delivered')} (${state.deliveredOrders.length})`, content: state.deliveredOrders, productState: ''},
  ]

  const orderNextStep = async (item: IOrder) => {
    await dispatch(selectOrderStepNext(item, state.selectedFilter));
  };

  const renderButtonsText = (id: string) => {
    const notStartedText = state.waitingOrders.filter(item => item.cartID === id)[0];
    if (notStartedText !== undefined) return 'Aloita';
    const inProgressText = state.preparingOrders.filter(item => item.cartID === id)[0];
    if (inProgressText !== undefined) return 'Valmis';
    const isFinishedText = state.readyOrders.filter(item => item.cartID === id)[0];
    if (isFinishedText !== undefined) return 'Sulje';
  }

  const deleteOrMoveBackOrder = async (order: IOrder) => {
    await dispatch(selectOrderStepBack(order));
  };

  const renderLeftTabContent = () => {
    const activeTab = leftSideTabs.find(tab => tab.id === leftSideActiveTab);
    if (!activeTab) return null;
    return (
      <div className={styles.tabContent}>
        <div className={styles.content}>
          {activeTab.content.map(item => (
            <OrderItem
              key={item.cartID}
              data={item}
              orderNextStep={orderNextStep}
              deleteOrMoveBackOrder={deleteOrMoveBackOrder}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderRightTabContent = () => {
    const activeTab = rightSideTabs.find(tab => tab.id === rightSideActiveTab);
    if (!activeTab) return null;
    return (
      <div className={styles.tabContent}>
        <div className={styles.content}>
          {activeTab.content.map(item => (
            <OrderItem
              key={item.cartID}
              data={item}
              orderNextStep={orderNextStep}
              deleteOrMoveBackOrder={deleteOrMoveBackOrder}
              productState={activeTab.productState}
            />
          ))}
        </div>
      </div>
    );
  };
  
  const hallOrdersToShow = (id: number): IOrder[] => {
    return id === 0 ? state.waitingOrders :
      id === 1 ? [...state.preparingOrders, ...state.readyOrders] :
        state.deliveredOrders;
  }


  return (
    <>
      <div className={place === PlaceEnum.KITCHEN ? styles.homeKitchen : styles.homeHall}>
        <div className={styles.container}>
          {
            place === PlaceEnum.HALL ?
              columnTitles.map(col => (
                <Column
                  key={col.id}
                  title={col.title}
                  data={hallOrdersToShow(col.id)}
                  place={place}
                />
              ))
              :
              <>
                <div className={`${styles.column} ${styles.leftSide}`}>
                  <div className={styles.tabs}>
                    <div className={styles.tabButtons}>
                      {
                        leftSideTabs.map(({id, title}) => (
                          <div
                            key={id}
                            onClick={() => setLeftSideActiveTab(id)}
                            className={`${styles.tab} ${leftSideActiveTab !== id ? styles.notActiveTab : ''}`}
                          >
                            {title}
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  {renderLeftTabContent()}
                </div>

                <div className={`${styles.column} ${styles.rightSide}`}>
                  <div className={styles.tabs}>
                    <div className={styles.tabButtons}>
                      {
                        rightSideTabs.map(({id, title}) => (
                          <div
                            key={id}
                            onClick={() => setRightSideActiveTab(id)}
                            className={`${styles.tab} ${rightSideActiveTab !== id ? styles.notActiveTab : ''}`}
                          >
                            {title}
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  {renderRightTabContent()}
                </div>
              </>
          }
        </div>
        {place === PlaceEnum.KITCHEN && <SideBar/>}
        <Footer/>
      </div>
      {state.menuSettings.isShowMenu && <SideBarWindow/>}
    </>
  );
});