import { useState } from 'react';

function useUpdate() {

  const keyLocalStorage = 'posmanagerBasketKitchenUpdate';

  const [updateExists, setUpdateExists] = useState<boolean>(false);

  const getCurrentMark = () : string|null => {
    return localStorage.getItem(keyLocalStorage);
  }
  const setCurrentMark = (mark: string) => {
    return localStorage.setItem(keyLocalStorage, mark);
  }

  const buildMark = (response: Response) : string | null => {
    return response.headers.get("ETag");
  }

  const loadContent = async (forceRefresh: boolean = false) : Promise<Response | null> => {
    const stamp:string = new Date().getMilliseconds().toString();
    const url:string = `${window.location.href}${forceRefresh?`?tsupdate=${stamp}`:''}`;
    try {
      const result = await fetch(url);
      if (result.status === 304) return null;
      if (Math.floor(result.status / 100) === 4) return null; // status 4xx
      if (Math.floor(result.status / 100) === 5) return null; // status 5xx
      return result;
    } catch {}
    return null;
  }

  const initializeUpdate = () => {
    try {
      loadContent(true).then( (content :Response|null) => {
        if (content==null)return;
        const mark = buildMark(content);
        if (mark){
          setCurrentMark(mark);
        }
      });
    } catch {}
  }

  const checkForUpdate = () => {
    try {
      loadContent().then( (content :Response|null) => {
        if (content==null)return;
        const currentMark = getCurrentMark();
        const mark = buildMark(content);
        if (mark == null)return;
        if (currentMark != null){
          if (currentMark !== mark){
            setUpdateExists(true);
            //console.log('UpdateApp', `Updated mark: ${mark}`);
            setCurrentMark(mark);
          }
        } else {
          //console.log('UpdateApp', `New mark: ${mark}`);
          setCurrentMark(mark);
        }
      });
    } catch {}
  }

  return  { updateExists: updateExists, initializeUpdate: initializeUpdate, checkForUpdate: checkForUpdate };
}

export default useUpdate;