import React, { ReactNode, useState } from 'react';
import styles from './styles.module.scss';
import { RootState, useAppDispatch } from "../../store/store";
import { filterOrders, setPosDetailsAction, showMenu } from "../../store/displaySlice";
import IPosDetails, { ButtonNameEnum } from "../../types/types";
import { useSelector } from "react-redux";
import { FilterContent } from "./filterContent/FilterContent";
import { OrdersContent } from "./ordersContent/OrdersContent";
import { LanguageContent } from "./languageContent/LanguageContent";
import { LocationContent } from "./locationContent/LocationContent";
import { useTranslation } from "react-i18next";
import { getOrders } from "../../api";


export const SideBarWindow = () => {
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.display);
  const {i18n, t} = useTranslation();
  const [selectedLng, setSelectedLng] = useState<string>('')
  const [selectedLocation, setSelectedLocation] = useState<IPosDetails>()
  
  const acceptLanguageChange = (): void => {
    i18n.changeLanguage(selectedLng).then();
    dispatch(showMenu({isShowMenu: false, content: null}));
  }
  
  const acceptLocationChange = (): void => {
    if (selectedLocation !== undefined) dispatch(setPosDetailsAction(selectedLocation));
    if (selectedLocation !== undefined) dispatch(getOrders(selectedLocation?.shopCode!))
    dispatch(showMenu({isShowMenu: false, content: null}));
  }

  const handleConfirm = (): void => {
    switch (state.menuSettings.content) {
      case ButtonNameEnum.FILTER:
        dispatch(filterOrders());
        break;
      case ButtonNameEnum.ORDERS:
        break;
      case ButtonNameEnum.LANGUAGE:
        acceptLanguageChange()
        break;
      case ButtonNameEnum.LOCATION:
        acceptLocationChange();
        break;
    }
  };
  
  const getContent = (): ReactNode => {
    switch (state.menuSettings.content) {
      case ButtonNameEnum.FILTER:
        return <FilterContent />;
      case ButtonNameEnum.ORDERS:
        return <OrdersContent />;
      case ButtonNameEnum.LANGUAGE:
        return <LanguageContent selectedLanguage={setSelectedLng} />;
      case ButtonNameEnum.LOCATION:
        return <LocationContent selectedLocation={setSelectedLocation}/>;
      default:
        return null;
    }
  };


  return (
    <div className={`${state.menuSettings.content === ButtonNameEnum.ORDERS ? styles.finishedOrders : styles.sideBarWindow}`}>
      <div className={`${state.menuSettings.content === ButtonNameEnum.ORDERS ? '' : styles.container}`}>
        {getContent()}
        {state.menuSettings.content !== ButtonNameEnum.ORDERS &&
          <div className={styles.buttons}>
            <button onClick={handleConfirm}>{t('button.confirm')}</button>
            <button onClick={() => dispatch(showMenu({isShowMenu: false, content: null}))}>
              {t('button.cancel')}
            </button>
          </div>}
      </div>
    </div>
  );
};
