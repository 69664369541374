import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import '../src/assets/index.scss';
import './i18n/config';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import store from "./store/store";
import {Provider} from 'react-redux';

export const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);

const root: ReactDOM.Root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Suspense fallback={<>Loading.....</>} >
          <Provider store={store}>
            <App />
          </Provider>
        </Suspense>
      </MsalProvider>
  </React.StrictMode>
);

reportWebVitals();
