import { callApi, callApiAnonymous } from "./helpers/callApi";
import { IOrder, IShop, OrderStateEnum } from "./types/types";
import { AppDispatch } from "./store/store";
import {
  addOrderToStateAction, getFilterNames,
  getOrdersAction, getShops,
  loginUserAction,
  removeOrderFromStateAction
} from "./store/displaySlice";
import { loginRequest } from "./authConfig";
import { AccountInfo, InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";


export const getOrders = (shopCode: string) => async (dispatch: AppDispatch): Promise<void> => {
  try {
    const response = await callApiAnonymous.get(`kitchenorder/${shopCode}/kitchenorders`);
    if (response.status === 200) {
      dispatch(getOrdersAction(response.data))
    }
  } catch (e: any) {
    console.log(e.message);
  }
}

export const selectOrderStepBack = (order: IOrder) => async (dispatch: AppDispatch): Promise<void> => {
  let updatedOrder = order;
  let newOrderState = OrderStateEnum.WAITING;

  if (order.orderState !== OrderStateEnum.WAITING) {
    switch (order.orderState) {
      case OrderStateEnum.PREPARING:
        newOrderState = OrderStateEnum.WAITING ;
        break;
      case OrderStateEnum.COLLECTING:
        newOrderState = OrderStateEnum.PREPARING;
        break;
      case OrderStateEnum.READY:
        newOrderState = OrderStateEnum.COLLECTING;
        break;
      case OrderStateEnum.DELIVERED:
        newOrderState = OrderStateEnum.READY;
        break;
      default:
        break;
    }
    updatedOrder = {...order, orderState: newOrderState};
  } else {
    await removeOrder(order.cartID);
    dispatch(removeOrderFromStateAction(order))
    return;
  }
  try {
    const res = await callApi.put<IOrder>(`kitchenorder/${order.cartID}`, updatedOrder)
    if (res.status === 200) {
      dispatch(removeOrderFromStateAction(order))
      dispatch(addOrderToStateAction(res.data))
    }
  } catch (e: any) {
    console.log(e.message);
  }
}

export const selectOrderStepNext = (order: IOrder, conseptNames: string[]) => async (dispatch: AppDispatch): Promise<void> => {
  try {
    let newOrderState = OrderStateEnum.WAITING;

    switch (order.orderState) {
      case OrderStateEnum.WAITING:
        newOrderState = OrderStateEnum.PREPARING;
        break;
      case OrderStateEnum.PREPARING:
        newOrderState = OrderStateEnum.COLLECTING ;
        break;
      case OrderStateEnum.COLLECTING:
        newOrderState = OrderStateEnum.READY;
        break;
      case OrderStateEnum.READY:
        newOrderState = OrderStateEnum.DELIVERED;
        break;
      default:
        break;
    }

    const updatedOrder = {
      tableNumber: order.tableNumber,
      orderState: order.orderState !== OrderStateEnum.DELIVERED ? newOrderState : order.orderState,
      deliveryMethod: order.deliveryMethod,
      pickUpTime: order.pickUpTime,
      conseptNames: (order.orderState === OrderStateEnum.PREPARING || order.orderState === OrderStateEnum.COLLECTING) ? conseptNames : [],
    };
    const res = await callApi.put<IOrder>(`kitchenorder/${order.cartID}`, updatedOrder)
    if (res.status === 200) {
      dispatch(removeOrderFromStateAction(order))
      dispatch(addOrderToStateAction(res.data))
    }
  } catch (e: any) {
    console.log(e.message);
  }
}

export const removeOrder = async (id: string): Promise<void> => {
  try {
    const res = await callApi.delete(`kitchenorder/${id}`);
    console.log(res)
  } catch (e: any) {
    console.log(e.message);
  }
}

export const setProductIsReadyState = (cartId: string, isDone: { [key: string]: boolean }) => async (): Promise<void> => {
  try {
    const res = await callApi.put(`kitchenorder/${cartId}/rowstate`, {isDone});
    console.log(res)
  } catch (e: any) {
    console.log(e.message)
  }
}

export const getConseptNames = (shopCode: string | undefined) => async (dispatch: AppDispatch): Promise<void> => {
  try {
    const res = await callApi.get<string[]>(`kitchenorder/${shopCode}/conseptnames`)
    dispatch(getFilterNames(res.data));
  } catch (e: any) {
    console.log(e.message)
  }
}

export const getAllShops = () => async (dispatch: AppDispatch) => {
  try {
    const res = await callApi.get<IShop[]>('search/shops');
    if (res.status === 200)
      dispatch(getShops(res.data))
  } catch (e: any) {
    console.error(e.message)
  }
}

export const getUserDetails = (instance: IPublicClientApplication, accounts: AccountInfo[], inProgress: InteractionStatus) => async (dispatch: AppDispatch) => {
  if (accounts.length > 0 && inProgress === 'none') {
    try {
      instance.setActiveAccount(accounts[0]);
      await instance.acquireTokenSilent({...loginRequest,});
      if (instance.getActiveAccount()?.idTokenClaims !== null) {
        const claims: {country: string} = instance.getActiveAccount()?.idTokenClaims as { country: string; };
        console.log('CLAIMS', claims);
        dispatch(loginUserAction(instance.getActiveAccount()?.name));
      }
    } catch (e) {
      console.error('Something went wrong, please refresh the page', e);
      await instance.loginRedirect();
    }
  }
}