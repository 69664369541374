import React from 'react';
import styles from './styles.module.scss';
import TuneIcon from '@mui/icons-material/Tune';
import LanguageIcon from '@mui/icons-material/Language';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { showMenu } from "../../store/displaySlice";
import { ButtonNameEnum, IButton } from "../../types/types";
import { useTranslation } from "react-i18next";

export const SideBar = () => {
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.display);
  const {t, i18n} = useTranslation();
  
  const handleButtonClick = (buttonName: ButtonNameEnum): void => {
    if (state.menuSettings.content === buttonName) {
      dispatch(showMenu({isShowMenu: false, content: null}));
    } else {
      dispatch(showMenu({isShowMenu: true, content: buttonName}));
    }
  }
  
  const renderButtons: IButton[] = [
    {id: 0, icon: <TuneIcon/>, title: t('sideBar.filter'), name: ButtonNameEnum.FILTER},
    {id: 1, icon: <ReceiptLongIcon/>, title: t('sideBar.finished_orders'), name: ButtonNameEnum.ORDERS},
    {id: 2, icon: <LanguageIcon/>, title: `${t('sideBar.language')} ${i18n.language.toUpperCase()}`, name: ButtonNameEnum.LANGUAGE},
    {id: 3, icon: <WhereToVoteIcon/>, title: t('sideBar.office'), name: ButtonNameEnum.LOCATION},
  ]
  
  return (
    <div className={styles.sideBar}>
      <div className={styles.upSideButtons}>

        {renderButtons.slice(0, 2).map(button => (
          <div 
            key={button.id}
            className={`${styles.buttonItem} ${state.menuSettings.content === button.name && state.menuSettings.isShowMenu ? styles.active : ''}`}
            onClick={() => handleButtonClick(button.name)}
          >
            {button.icon}
            {button.title}
          </div>
        ))}
        
      </div>
      <div className={styles.downSideButtons}>

        {renderButtons.slice(2).map(button => (
          <div
            key={button.id}
            className={`${styles.buttonItem} ${state.menuSettings.content === button.name && state.menuSettings.isShowMenu ? styles.active : ''}`}
            onClick={() => handleButtonClick(button.name)}
          >
            {button.icon}
            {button.title}
          </div>
        ))}
        
      </div>
    </div>
  );
};