import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { getPosDetailsFromLocalstorage, setPosDetails } from "../helpers/localStorageHelper";
import { ICustomerContext } from "./types";
import { ButtonNameEnum, IOrder, IShop, OrderStateEnum } from "../types/types";
import { orderFiltering } from "../helpers/filterOrders";
import { separateOrders } from "../helpers/separateOrders";

const initialState: ICustomerContext = {
  menuSettings: {
    isShowMenu: false,
    content: null
  },
  shops: [],
  filterNames: [],
  selectedFilter: [],
  operator: null,
  posDetails: getPosDetailsFromLocalstorage(),
  orders: [],
  waitingOrders: [],
  preparingOrders: [],
  readyOrders: [],
  deliveredOrders: [],
  completedOrders: []
}

const displaySlice = createSlice({
  name: 'display',
  initialState: initialState,
  reducers: {
    
    loginUserAction(state, action) {
      state.operator = action.payload;
    },
    
    setPosDetailsAction(state, action) {
      state.posDetails = setPosDetails(action.payload) ? action.payload : null;
    },

    getOrdersAction(state, action: PayloadAction<IOrder[]>) {
      const updatedOrders = action.payload;
      const updatedState = {
        ...state,
        orders: updatedOrders,
      };
      
      if (updatedOrders.length === 0) {
        state.waitingOrders = [];
        state.preparingOrders = [];
        state.readyOrders = [];
        state.deliveredOrders = [];
        state.completedOrders = [];
      } else {
        return updatedOrders.reduce((currentState, order) => separateOrders(currentState, order, state.selectedFilter.length > 0, state.selectedFilter), updatedState);
      }
    },
    
    removeOrderFromStateAction(state, action: PayloadAction<IOrder>) {
      const order = action.payload;

      if (order.orderState === OrderStateEnum.WAITING) {
        state.waitingOrders = state.waitingOrders.filter(i => i.cartID !== order.cartID)
      }

      if (order.orderState === OrderStateEnum.PREPARING) {
        state.preparingOrders = state.preparingOrders.filter(i => i.cartID !== order.cartID)
      }

      if (order.orderState === OrderStateEnum.COLLECTING) {
        state.readyOrders = state.readyOrders.filter(i => i.cartID !== order.cartID)
      }

      if (order.orderState === OrderStateEnum.READY) {
        state.deliveredOrders = state.deliveredOrders.filter(i => i.cartID !== order.cartID)
      }

      if (order.orderState === OrderStateEnum.DELIVERED) {
        state.completedOrders = state.completedOrders.filter(i => i.cartID !== order.cartID)
      }
    },
    
    addOrderToStateAction(state, action: PayloadAction<IOrder>) {
      const order = action.payload;

      if (order.orderState === OrderStateEnum.WAITING) {
        state.waitingOrders = [...state.waitingOrders, order]
      }

      if (order.orderState === OrderStateEnum.PREPARING) {
        state.preparingOrders = [...state.preparingOrders, order]
      }

      if (order.orderState === OrderStateEnum.COLLECTING) {
        state.readyOrders = [...state.readyOrders, order]
      }

      if (order.orderState === OrderStateEnum.READY) {
        state.deliveredOrders = [...state.deliveredOrders, order]
      }

      if (order.orderState === OrderStateEnum.DELIVERED) {
        state.completedOrders = [...state.completedOrders, order]
      }
    },
    
    showMenu(state, action: PayloadAction<{isShowMenu: boolean, content: ButtonNameEnum | null}>) {
      state.menuSettings = {
        isShowMenu: action.payload.isShowMenu,
        content: action.payload.content
      }
    },
    
    getFilterNames(state, action: PayloadAction<string[]>) {
      state.filterNames = action.payload;
    },

    toggleFilter(state, action: PayloadAction<string>) {
      const isIncluded: boolean = state.selectedFilter.includes(action.payload);
      if (isIncluded) {
        state.selectedFilter = state.selectedFilter.filter(selected => selected !== action.payload);
      } else {
        state.selectedFilter = [...state.selectedFilter, action.payload]
      }
    },
    
    filterOrders(state) {
      const filteredOrders = state.orders.filter(order =>
        order.orderedProducts.some(product => state.selectedFilter.includes(product.conseptName))
      );

      const updatedState = {
        ...state,
        waitingOrders: orderFiltering(
          filteredOrders.filter(order => order.orderState === OrderStateEnum.WAITING),
          state.selectedFilter
        ),
        preparingOrders: orderFiltering(
          filteredOrders.filter(order => order.orderState === OrderStateEnum.PREPARING),
          state.selectedFilter
        ),
        readyOrders: orderFiltering(
          filteredOrders.filter(order => order.orderState === OrderStateEnum.COLLECTING),
          state.selectedFilter
        ),
        deliveredOrders: orderFiltering(
          filteredOrders.filter(order => order.orderState === OrderStateEnum.READY),
          state.selectedFilter
        ),
        menuSettings: { isShowMenu: false, content: null }
      };

      return filteredOrders.reduce((currentState: ICustomerContext, order: IOrder) => 
        separateOrders(currentState, order, state.selectedFilter.length > 0, state.selectedFilter), updatedState);

    },
    
    getShops(state, action: PayloadAction<IShop[]>) {
      state.shops = action.payload;
    }

  }
})

export default displaySlice.reducer
export const {
  getOrdersAction,
  loginUserAction,
  setPosDetailsAction,
  removeOrderFromStateAction,
  addOrderToStateAction,
  showMenu,
  getFilterNames,
  toggleFilter,
  filterOrders,
  getShops
} = displaySlice.actions