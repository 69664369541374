import { ReactNode } from "react";

export enum DeliveryMethodEnum {
  FRONT_COUNTER = 'FrontCounter',
  TABLE_SERVICE='TableService',
  TAKE_AWAY = 'Takeaway',
  CURB_SIDE = 'Curbside',
  TABLE_SERVING = 'TableServing',
  TAKE_AWAY_TABLE_SERVING = 'TakeawayTableServing',
}

export enum OrderStateEnum {
  WAITING = 'Waiting',
  PREPARING = 'Preparing',
  COLLECTING = 'Collecting',
  READY = 'Ready',
  DELIVERED = 'Delivered'
}

export enum PlaceEnum {
  KITCHEN = 'KITCHEN',
  HALL = 'HALL'
}

export enum ButtonNameEnum {
  FILTER, 
  ORDERS,
  LANGUAGE,
  LOCATION
}

export default interface IPosDetails {
  terminalCode: string;
  shopCode: string;
  posCode: string;
}

export interface IProduct {
  conseptName: string
  infoText: string
  isContent: boolean
  isPrepared: boolean
  isCollected: boolean
  productCode: string
  productInfo: string
  productName: string
  products: []
  quantity: number
  rowId: string
}

export interface IOrder {
  cartID: string
  deliveryMethod: DeliveryMethodEnum
  isPaid: boolean
  orderDate: string
  orderState: OrderStateEnum
  orderedProducts: IProduct[]
  pickUpTime: string
  serviceID: string
  servingID: string
  registrationNumber: string
  tableNumber: string
}

export interface IButton {
  id: number
  icon: ReactNode
  title: string
  name: ButtonNameEnum
}

export interface IShop {
  shopCode: string
  shopName: string
}