import { useState, useEffect } from 'react';
import useUpdate from './useUpdate';

export default function UpdateTimer () {

  const { updateExists, initializeUpdate, checkForUpdate } = useUpdate();
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [triggerCount, setTriggerCount] = useState<number>(60*60);

  useEffect(() => {
    const tick = () => {
      if (firstRun){
        setFirstRun(false);
        initializeUpdate();
      } else {
          if (count>=triggerCount){
          setCount(0);
          checkForUpdate();
        } else {
          setCount((c) => c+1);
        }
      }
    }
    const timeoutId:NodeJS.Timeout = setTimeout(() => tick(),1000);
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  },[checkForUpdate, count, firstRun, initializeUpdate, triggerCount]);

  useEffect(() => {
    if (updateExists){
      window.location.reload();
    }
  },[updateExists]);

  const reset = () => {
    setCount(0);
  }

  return  {
    updateCounter: count,
    updateCounterTrigger: triggerCount,
    updateCounterTriggerSet: setTriggerCount,
    updateCounterReset: reset
  };
}
