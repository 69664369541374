import React, { FC } from 'react';
import styles from './styles.module.scss';

interface Props {
  title: string
}
export const Title: FC<Props> = ({title}) => {
  return (
    <div className={styles.title}>
      <h1>{title}</h1>
    </div>
  );
};
