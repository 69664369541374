import { Configuration, LogLevel } from '@azure/msal-browser';

const ClientID = process.env.REACT_APP_CLIENTID || '';
const Authority = process.env.REACT_APP_AUTHORITY || '';
const KnowAuthorities = process.env.REACT_APP_KNOWNAUTHORITIES || '';
const Scopes = process.env.REACT_APP_SCOPES || '';

export const msalConfig: Configuration = {
  auth: {
    clientId: ClientID,
    authority: Authority,
    knownAuthorities: [KnowAuthorities],
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            // console.error(message);
        }
      },
    },
  },
};


export const loginRequest: {scopes: string[]} = {
  scopes: [Scopes],
};

export const tokenRequest: {scopes: string[]} = {
  scopes: [Scopes],
};

export const tokenRefreshRequest: {scopes: string[], forceRefresh: boolean} = {
  scopes: [Scopes],
  forceRefresh: true,
};
