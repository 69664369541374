import React, { useEffect, useState } from 'react';
import IPosDetails, { PlaceEnum } from "./types/types";
import { Home } from "./pages/home/Home";
import { useLanguageTimer } from "./hooks/useLanguageTimer";
import { getOrders, getUserDetails } from "./api";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, } from '@azure/msal-react';
import MsalLogin from "./components/MsalLogin";
import { setPosDetailsAction } from "./store/displaySlice";
import { RootState, useAppDispatch } from "./store/store";
import {useSelector} from 'react-redux';
import { useTranslation } from "react-i18next";
import UpdateTimer from './hooks/updateTimer';


function App() {
  const {i18n} = useTranslation();
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.display);
  const {instance, accounts, inProgress} = useMsal();
  const [renderPlace, setRenderPlace] = useState(PlaceEnum.KITCHEN)
  const { updateCounterTriggerSet } = UpdateTimer();
  
  useLanguageTimer(5000, renderPlace);

  useEffect(() => {
    i18n.changeLanguage('fi').then();
    updateCounterTriggerSet(60*60); // 60s * 60m = 1h
  }, []);

  useEffect((): void => {
    dispatch(getUserDetails(instance, accounts, inProgress));
  }, [dispatch, instance, accounts, inProgress]);

  useEffect((): void => {
    const params: URLSearchParams = new URLSearchParams(window.location.search);
    let prmPos: string = params.get('pos') ?? '1';
    let prmTerminal: string = params.get('terminal') ?? '1';
    const prmPlace: string = params.get('hall') ?? '0';
    const posDetails: IPosDetails | null = state.posDetails;
    if (prmPlace === '1') setRenderPlace(PlaceEnum.HALL);
    if (posDetails == null || posDetails.posCode !== prmPos || posDetails.terminalCode !== prmTerminal) {
      const defaultValue: IPosDetails = {terminalCode: prmTerminal, shopCode: state.posDetails?.shopCode!, posCode: prmPos};
      dispatch(setPosDetailsAction(defaultValue));
    }
  }, [state.posDetails, dispatch])

  useEffect(() => {
    const intervalId: NodeJS.Timer = setInterval( () => {
      dispatch(getOrders(state.posDetails?.shopCode!));
    }, 5000);
    return () => clearInterval(intervalId);
  }, [state.posDetails?.shopCode]);
  
  return (
    <Router>
      <UnauthenticatedTemplate>
        <MsalLogin/>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Home place={renderPlace}/>}/>
          <Route path="*" element={<>404</>}/>
        </Routes>
      </AuthenticatedTemplate>
    </Router>
  )
}

export default App;
