import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { CheckBox } from "../../checkBox/CheckBox";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { getConseptNames } from "../../../api";
import { Title } from "../../title/Title";
import { useTranslation } from "react-i18next";



export const FilterContent = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const state = useSelector((state: RootState) => state.display);

  useEffect((): void => {
    dispatch(getConseptNames(state.posDetails?.shopCode!));
  }, []);
  
  return (
    <div className={styles.filterContent}>
      <Title title={t('sideBar.filter')} />
      <div className={styles.content}>
        {
          state.filterNames.map(name => (
            <CheckBox key={name} label={name} />
          ))
        }
      </div>
    </div>
  );
};
