import { useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { PlaceEnum } from "../types/types";

export const useLanguageTimer = (interval = 5000, place: string): void => {
  const languages = useMemo(() => ['en', 'fi', 'sv'], []);
  const [languageIndex, setLanguageIndex] = useState(0);

  
    useEffect(() => {
      if (place === PlaceEnum.HALL) {
        const timer: NodeJS.Timer = setInterval((): void => {
          setLanguageIndex((prevIndex) => (prevIndex + 1) % languages.length);
        }, interval);
        return () => clearInterval(timer);
      }
    }, [place, languages, interval]);

    useEffect((): void => {
      i18n.changeLanguage(languages[languageIndex]);
    }, [languageIndex, languages]);
  
};