import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Title } from "../../title/Title";
import { RootState, useAppDispatch } from "../../../store/store";
import { getAllShops } from "../../../api";
import { useSelector } from "react-redux";
import { RadioButton } from "../../radioButton/RadioButton";
import { useTranslation } from "react-i18next";
import { getPosDetailsFromLocalstorage } from "../../../helpers/localStorageHelper";
import IPosDetails from "../../../types/types";


interface Props {
  selectedLocation: (value: IPosDetails) => void;
}
export const LocationContent: FC<Props> = ({selectedLocation}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();  
  const state = useSelector((state: RootState) => state.display);
  const [shopValue, setShopValue] = useState<string>(state.posDetails?.shopCode!);
  const [posDetails] = useState<IPosDetails | null>(getPosDetailsFromLocalstorage())

  useEffect(() => {
    dispatch(getAllShops())
  }, [dispatch]);
  
  const handleChangeValue = (value: string): void => {
    setShopValue(value);
    selectedLocation({
      shopCode: value,
      terminalCode: posDetails?.terminalCode || '',
      posCode: posDetails?.posCode || '',
    });
  }
  
  return (
    <div className={styles.locationContent}>
      <Title title={t('change_office')} />
      <div className={styles.content}>
        {
          state.shops.map(shop => (
            <RadioButton 
              key={shop.shopCode} 
              label={shop.shopName} 
              value={shop.shopCode}
              onChange={handleChangeValue}
              selected={shopValue === shop.shopCode} />
          ))
        }
      </div>
    </div>
  );
};
