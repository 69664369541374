import React, { FC, useEffect, useState } from 'react';
import { IOrder, IProduct, OrderStateEnum, DeliveryMethodEnum } from "../../types/types";
import styles from './styles.module.scss';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { format } from "date-fns";
import { RootState, useAppDispatch } from "../../store/store";
import { setProductIsReadyState } from "../../api";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface Props {
  data: IOrder
  orderNextStep?: (item: IOrder) => void;
  deleteOrMoveBackOrder: (order: IOrder) => void;
  productState?: string
}

export const OrderItem: FC<Props> = React.memo(({data, orderNextStep, deleteOrMoveBackOrder, productState}) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(data.orderState === OrderStateEnum.WAITING || data.orderState === OrderStateEnum.DELIVERED);
  const [checked, setChecked] = useState<string[]>([]);
  const state = useSelector((state: RootState) => state.display);

  useEffect(() => {
    takeAllProductsCheckboxes(data.orderedProducts);
  }, [data.orderedProducts]);

  const takeAllProductsCheckboxes = (products: IProduct[]): (IProduct | false)[] => {
    return products.flatMap(item => {
      if (productState !== undefined && productState in item) {
        if (item[productState as keyof IProduct] === true) {
          setChecked(prevState => [...prevState, item.rowId])
        }
      }
      return item.products && item.products.length > 0 && takeAllProductsCheckboxes(item.products);
    })
  }
  
  const deliveryMethod = (): string => {
    let deliveryMethod: string = '';
    if (data.deliveryMethod === DeliveryMethodEnum.TAKE_AWAY) deliveryMethod = t('away');
    if (data.deliveryMethod === DeliveryMethodEnum.FRONT_COUNTER) deliveryMethod = t('front_counter');
    if (data.deliveryMethod === DeliveryMethodEnum.CURB_SIDE) deliveryMethod = t('curb_side');
    if (data.deliveryMethod === DeliveryMethodEnum.TABLE_SERVICE) deliveryMethod = t('table_service');
    if (data.deliveryMethod === DeliveryMethodEnum.TABLE_SERVING) deliveryMethod = t('table_serving');
    if (data.deliveryMethod === DeliveryMethodEnum.TAKE_AWAY_TABLE_SERVING) deliveryMethod = t('away_table_serving');
    return deliveryMethod;
  }

  const renderBorderStyles = () => {
    let initStyle: string = styles.kitchenCard;
    if (data.deliveryMethod === DeliveryMethodEnum.TAKE_AWAY) initStyle = `${initStyle} ${styles.takeAway}`;
    if (data.deliveryMethod === DeliveryMethodEnum.FRONT_COUNTER) initStyle = `${initStyle} ${styles.frontCounter}`;
    if (data.deliveryMethod === DeliveryMethodEnum.TABLE_SERVICE) initStyle = `${initStyle} ${styles.tableService}`;
    if (data.deliveryMethod === DeliveryMethodEnum.CURB_SIDE) initStyle = `${initStyle} ${styles.curbSide}`;
    if (data.deliveryMethod === DeliveryMethodEnum.TABLE_SERVING) initStyle = `${initStyle} ${styles.tableServing}`;
    if (data.deliveryMethod === DeliveryMethodEnum.TAKE_AWAY_TABLE_SERVING) initStyle = `${initStyle} ${styles.takeAwayTableServing}`;
    return initStyle;
  }

  const headerBackground = () => {
    let backgroundColor: string = '';
    if (data.deliveryMethod === DeliveryMethodEnum.TAKE_AWAY) backgroundColor = styles.takeAway;
    if (data.deliveryMethod === DeliveryMethodEnum.FRONT_COUNTER) backgroundColor = styles.frontCounter;
    if (data.deliveryMethod === DeliveryMethodEnum.TABLE_SERVICE) backgroundColor = styles.tableService;
    if (data.deliveryMethod === DeliveryMethodEnum.CURB_SIDE) backgroundColor = styles.curbSide;
    if (data.deliveryMethod === DeliveryMethodEnum.TABLE_SERVING) backgroundColor = styles.tableServing;
    if (data.deliveryMethod === DeliveryMethodEnum.TAKE_AWAY_TABLE_SERVING) backgroundColor = styles.takeAwayTableServing;
    return backgroundColor;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, prodId: string): void => {
    const checkedState: boolean = event.target.checked;
    const newChecked: string[] = [...checked];
    if (checkedState && !newChecked.includes(prodId)) {
      setChecked(prevState => [...prevState, prodId])
    } else {
      setChecked(prevState => prevState.filter(prod => prod !== prodId))
    }

    const isDone: { [key: string]: boolean } = {};
    isDone[prodId] = checkedState;
    dispatch(setProductIsReadyState(data.cartID, isDone))
  };

  const renderProducts = (products: IProduct[], conseptName: string): JSX.Element[] => {
    return products.flatMap((product) => {
      const currentIndex = product.rowId;
      return (
        product.quantity === 0 && product.productName.includes('Ei') ?
          <div key={product.rowId} className={styles.productsData}>
            <div className={styles.productLine}>
              <div className={styles.productName}>
              <span className={styles.notIncludedProduct}>
                Ei {product.productName.replace('Ei', '')}
              </span>
              </div>
            </div>
            {product.products && product.products.length > 0 && renderProducts(product.products, product.conseptName === null? conseptName : product.conseptName )}
          </div>
          :
          <div key={product.rowId} className={styles.productsData}>
            <div className={styles.productLine}>
              <div className={styles.productName}>
                <span className={product.conseptName === null ? styles.includedProduct : ''}>{product.quantity}</span>
                <span>{product.productName}</span>
              </div>
              {data.orderState !== OrderStateEnum.WAITING && 
                data.orderState !== OrderStateEnum.READY &&
                data.orderState !== OrderStateEnum.DELIVERED &&
                (state.selectedFilter.includes(product.conseptName === null? conseptName : product.conseptName) || state.selectedFilter.length === 0) &&
                <label className={styles.checkBoxContainer}>
                  <input
                    type="checkbox"
                    checked={checked.includes(currentIndex)}
                    onChange={(e) => handleChange(e, currentIndex)}
                    // disabled={state.selectedFilter.includes(product.conseptName === null? conseptName : product.conseptName)}
                  />
                  <span className={styles.checkmark} />
                </label>
              }
            </div>
            {product.products && product.products.length > 0 && renderProducts(product.products, product.conseptName === null? conseptName : product.conseptName)}
          </div>
      );
    });
  };

  return (
    <div className={renderBorderStyles()}>
      <div className={`${styles.header} ${headerBackground()}`}>
        <div className={styles.number}>
          <span>{data.serviceID}</span>
          <span>{format(data.orderDate, 'HH:mm')}</span>
        </div>
        <div className={styles.deliveryMethod}>
          <span>{deliveryMethod()}</span>
          <span>{data.tableNumber && data.tableNumber}</span>
          <span>{data.servingID && data.servingID}</span>
          <span>{data.registrationNumber && data.registrationNumber}</span>          
          <span>{data.pickUpTime && format(data.pickUpTime, 'HH:mm')}</span>
        </div>
        <div className={styles.collapseButton}>
          <button 
            className={`${data.orderState === OrderStateEnum.DELIVERED ? styles.backBtnInFinishedOrders : ''}`} 
            onClick={() => setIsCollapsed(prevState => !prevState)}
          >
            {isCollapsed ? <OpenInFullIcon/> : <CloseFullscreenIcon/>}
          </button>
        </div>
      </div>

      <div className={`${isCollapsed ? styles.contentHidden : styles.content}`}>
        {renderProducts(data.orderedProducts, data.orderedProducts[0].conseptName)}
      </div>

      <div className={`${isCollapsed ? styles.buttonsHidden : styles.buttons} ${data.orderState === OrderStateEnum.WAITING ? styles.onButtonDiv : ''}`}>
        {data.orderState !== OrderStateEnum.DELIVERED ?
          data.orderState !== OrderStateEnum.WAITING && 
          <button onClick={() => deleteOrMoveBackOrder(data)}>
            <KeyboardDoubleArrowLeftIcon/>
          </button>
          :
          <button className={styles.backBtnInFinishedOrders} onClick={() => deleteOrMoveBackOrder(data)}>
            <KeyboardDoubleArrowLeftIcon/> {t('button.back')}
          </button>
        }
        {data.orderState !== OrderStateEnum.DELIVERED &&
          <button onClick={() => orderNextStep !== undefined && orderNextStep(data)}>
            {t('button.next')} <KeyboardArrowRightIcon/>
        </button>}
      </div>
    </div>
  );
});