import { IOrder, OrderStateEnum } from "../types/types";
import { ICustomerContext } from "../store/types";

export const separateOrders = (state: ICustomerContext, order: IOrder, filterApplied: boolean, selectedFilter: string[]) => {
  const orderExists = (existingOrder: IOrder) => existingOrder.cartID === order.cartID;

  const removeFromAllOrders = (currentState: ICustomerContext) => {
    return {
      ...currentState,
      waitingOrders: currentState.waitingOrders.filter(existingOrder => !orderExists(existingOrder)),
      preparingOrders: currentState.preparingOrders.filter(existingOrder => !orderExists(existingOrder)),
      readyOrders: currentState.readyOrders.filter(existingOrder => !orderExists(existingOrder)),
      deliveredOrders: currentState.deliveredOrders.filter(existingOrder => !orderExists(existingOrder)),
      completedOrders: currentState.completedOrders.filter(existingOrder => !orderExists(existingOrder)),
    };
  };

  state = removeFromAllOrders(state);

  const addToOrderState = (orders: IOrder[], orderState: OrderStateEnum): IOrder[] => {
    const updatedOrders = orders.filter(existingOrder => !orderExists(existingOrder));
    return [...updatedOrders, order];
  };

  if (filterApplied && !order.orderedProducts.some(product => selectedFilter.includes(product.conseptName))) {
    return state;
  }

  switch (order.orderState) {
    case OrderStateEnum.WAITING:
      return { ...state, waitingOrders: addToOrderState(state.waitingOrders, OrderStateEnum.WAITING) };
    case OrderStateEnum.PREPARING:
      return { ...state, preparingOrders: addToOrderState(state.preparingOrders, OrderStateEnum.PREPARING) };
    case OrderStateEnum.COLLECTING:
      return { ...state, readyOrders: addToOrderState(state.readyOrders, OrderStateEnum.COLLECTING) };
    case OrderStateEnum.READY:
      return { ...state, deliveredOrders: addToOrderState(state.deliveredOrders, OrderStateEnum.READY) };
    case OrderStateEnum.DELIVERED:
      return { ...state, completedOrders: addToOrderState(state.completedOrders, OrderStateEnum.DELIVERED) };
    default:
      return state;
  }
};
