import styles from './styles.module.scss';
import React, { FC } from "react";
import { IOrder, IProduct, PlaceEnum } from "../../types/types";
import { OrderDetails } from "../orderDetails/OrderDetails";

interface Props {
  title: string;
  data: IOrder[];
  place: PlaceEnum;
}
export const Column: FC<Props> = ({ title, data,place }) => {

  const renderProducts = (products: IProduct[]): JSX.Element[] => {
    return products.flatMap(product => (
      <>
        <span key={product.rowId}>{product.productName} x {product.quantity}</span>
        {product.products && product.products.length > 0 && renderProducts(product.products)}
      </>
    ));
  };
  
  
  return (
    <div className={styles.column}>
      <div className={styles.title}>
        <h1>{title}</h1>
      </div>
      <div className={`${place === PlaceEnum.KITCHEN ? styles.container : styles.hallContainer}`}>
        <div className={styles.content}>
          {data.map(order => (
            <ul key={order.cartID} className={place === PlaceEnum.KITCHEN ? styles.card : styles.cardHall}>
              <OrderDetails order={order} place={place}/>
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};