import React, { FC } from 'react';
import styles from './styles.module.scss';


interface Props {
  label: string
  selected?: boolean
  value?: string
  onChange: (value: string) => void
}
export const RadioButton: FC<Props> = ({label, selected, value, onChange}) => {
  return (
    <div className={styles.radioButton}>
      <label>
        <input 
          type="radio" 
          checked={selected} 
          value={value} 
          onChange={(e) => onChange(e.target.value)} 
        />
        <span className={styles.design}></span>
        <span className={styles.value}>{label}</span>
      </label>
    </div>
  );
};
