import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import styles from './styles.module.scss';
import { OrderItem } from "../../kitchenColumn/OrderItem";
import React from "react";
import { IOrder } from "../../../types/types";
import { selectOrderStepBack } from "../../../api";
import { useTranslation } from "react-i18next";



export const OrdersContent = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state.display);

  const deleteOrMoveBackOrder = async (order: IOrder) => {
    await dispatch(selectOrderStepBack(order));
  };

  return (
    <>
      <div className={styles.tab}>
        <span>{t('finished')}</span>
      </div>
      <div className={styles.ordersContent}>
        <div className={styles.content}>
          {state.completedOrders.map(item => (
            <OrderItem
              key={item.cartID}
              data={item}
              deleteOrMoveBackOrder={deleteOrMoveBackOrder}
            />
          ))}
        </div>
      </div>
    </>
  );
};
