import IPosDetails from "../types/types";



export const getPosDetailsFromLocalstorage = (): IPosDetails | null => {
  const posDetails: string | null = localStorage.getItem('posmanagerBasketPosDetails');
  if (posDetails === null || posDetails.length === 0) {
    localStorage.setItem('posmanagerBasketPosDetails','');
    return {terminalCode: '', shopCode: '', posCode: ''};
  }
  return JSON.parse(posDetails);
};

export const removePosDetails = (): void => {
  localStorage.removeItem('posmanagerBasketPosDetails');
};

export const setPosDetails = (posDetails: IPosDetails): boolean => {
  try {
    localStorage.setItem( 'posmanagerBasketPosDetails', posDetails ? JSON.stringify(posDetails):'');
    return true;
  } catch (e) {
    return false;
  }
};